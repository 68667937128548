body {
  margin: 0;
  font-family:
    "MessinaSans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  letter-spacing: -0.03em;
  font-feature-settings: "kern"; /* turns on kerning */
  text-rendering: optimizeLegibility; /* emphasise on legibility when rendering, turns on ligatures and kerning */
  -webkit-font-smoothing: antialiased; /* apply font anti-aliasing for Webkit on OSX */
  -moz-osx-font-smoothing: grayscale; /* apply font anti-aliasing for Firefox on OSX */
}

input,
textarea,
button {
  font-family: inherit;
  letter-spacing: inherit;
}

code {
  font-family: JetBrainsMono, ui-monospace, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

a {
  text-decoration-line: none;
}

abbr {
  text-decoration: underline dashed #999;
  text-underline-offset: 2px;
}
